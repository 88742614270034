<template>
  <b-modal
    id="check-in-confirmation-modal"
    centered
    size="md"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
    hide-footer
  >
    <template #modal-title> <h2 class="m-0">Enter Cnic</h2> </template>
    <validation-observer ref="editBookingUserFormValidation">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col md="12" class="px-2 py-2">
              <validation-provider
                  #default="{ errors }"
                  name="CNIC"
                  rules="required|integer|length:13"
                >
                  <b-form-input
                    id="cnic"
                    v-model="cnic"
                    :state="errors.length > 0 ? false : null"
                    name="cnic"
                    placeholder="61101XXXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-col>
        </b-form-row>
        <b-form-row v-if="cnic.length == 13">
            <div class="ml-auto mx-2">
               <b-button
                variant="success"
                size="md"
                class="text-truncate  mb-1"
                @click="validationForm"
              >
                Check In
              </b-button>
            </div>
        </b-form-row>
      </b-form>
    </validation-observer>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],
    props: {
    booking: Object,
  },
  data() {
    return {
      show : false,
      required,
      cnic:'',
    };
  },
  async mounted() {
  },
  methods: {
      ...mapActions({
      updateBooking: "appData/updateBooking",
      fetchSystemTime: "appData/fetchSystemTime",
    }),
    async getCurrentDate() {
      await this.fetchSystemTime()
      var systemDateTime  = await this.getSystemTime;
      var currentDate = new Date(systemDateTime);
      return currentDate
    },
    async getPakistanDateString(date){
      var currentTime = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[1].split(" ")[1]
      date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
      var day = date.split("/")[1]
      var month = date.split("/")[0]
      var year = date.split("/")[2]
      return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0') + ' ' + currentTime;
    },
    async validationForm() {
      try {
        const success = await this.$refs.editBookingUserFormValidation.validate();
        if (success) {
          await this.CheckedInBooking();
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async CheckedInBooking() {
    this.show = true;
      var currentDate = await this.getCurrentDate()
      currentDate = await this.getPakistanDateString(currentDate);
      let tempParams = {
        pk: this.booking.id,
        payload: {
          checked_in_cnic: this.cnic,
          checked_in: true,
          check_in_time: currentDate
        }
      }
      try {
        const res = await this.updateBooking(tempParams);
        if (res.status === 200) {
          this.$swal({
            title: "Checked in",
            icon: "success",
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("check-in-confirmation-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        console.error("Error checking in booking:", error);
      }
    },
    reset() {
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      getSystemTime: "appData/getSystemTime",
    }),
  },
  // watch: {
  //     cnic(newValue, oldValue){
  //       if(newValue){
  //          if (!/^(?=.*\d)(?!0{2,})\d{1,13}$/.test(newValue)) {
  //               return true
  //          } 
  //          else{
  //               return false
  //          }
  //       }
  //     },
  //   }
};
</script>

<style></style>
