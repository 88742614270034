<template>
    <b-modal id="booking-create-modal" title="Booking Create Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 class="m-0">Create Booking</h2>
      </template>
      <template v-if="isBusy">
        <div class="text-center text-danger my-2">
          <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-else>
        <validation-observer ref="bookingCreateFormValidation">
          <b-form @submit.prevent>
            <!-- city changes -->

            <b-row v-if="!messSecretary && !messReceptionist">
              <b-col>
                  <validation-provider #default="{ errors }" name="City">
                      <b-form-group label-for="city" :state="errors.length > 0 ? false : null">
                          <template #label>
                          City <span class="text-danger">*</span>
                          </template>
                          <b-row>
                              <b-col>
                                  <v-select id="city" inputId="id" label="name" v-model="selectedCity"
                                  :options="cities" placeholder="City" class="v-style-chooser"/>
                              </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>                
              </b-col>
            </b-row>

            <!-- city changes -->
            <b-row>
              <b-col>
                  <validation-provider #default="{ errors }" name="Mess" :rules="{ required }">
                      <b-form-group label-for="mess" :state="errors.length > 0 ? false : null">
                          <template #label>
                          Mess <span class="text-danger">*</span>
                          </template>
                          <b-row>
                              <b-col>
                                  <v-select id="mess" inputId="id" label="name" v-model="selectedMess"
                                  :options="messes" placeholder="Mess" class="v-style-chooser" :disabled="messDisabled"/>
                              </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>                
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" sm="6" lg="6" xl="6">
                  <b-form-group label-for="checkinDate">
                    <template #label
                      ><span class="text-white font-weight-bolder">
                        Check-in Date
                      </span></template
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="checkinDatefield"
                      :rules="{ required }"
                    >
                      <b-input-group>
                        <b-form-input
                          id="checkinDateInput"
                          v-model="checkinDate"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="checkinDate"
                            v-model="checkinDate"
                            button-variant="primary"
                            :min="minCheckinDate"
                            :max="maxCheckinDate"
                            button-only
                            right
                            variant="primary"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
              <b-col md="6" sm="6" lg="6" xl="6">
                <b-form-group label-for="checkoutDate">
                    <template #label
                      ><span class="text-white font-weight-bolder">
                        Check-out Date
                      </span></template
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="checkoutDatefield"
                      :rules="{ required }"
                    >
                      <b-input-group>
                        <b-form-input
                          id="checkoutDateInput"
                          v-model="checkoutDate"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            id="checkoutDate"
                            v-model="checkoutDate"
                            button-variant="primary"
                            :max="maxCheckoutDate"
                            :min="minCheckoutDate"
                            button-only
                            right
                            variant="primary"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <validation-provider #default="{ errors }" name="BookingFor" :rules="{ required }">
                      <b-form-group label-for="bookingfor" :state="errors.length > 0 ? false : null">
                          <template #label>
                            Booking For <span class="text-danger">*</span>
                          </template>
                          <b-row>
                              <b-col>
                                  <v-select id="bookingfor" inputId="id" label="name" v-model="selectedBookingFor"
                                  :options="bookingforOptions" placeholder="Booking For" class="v-style-chooser"/>
                              </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>                
              </b-col>
            </b-row>
            <template v-if="selectedBookingFor">
              <b-row>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="cnic"
                    rules="required|integer|length:13" 
                  >
                    <b-form-group
                      label-for="cnic"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template #label>
                        CNIC <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="cnic"
                        v-model="cnic"
                        :state="errors.length > 0 ? false : null"
                        name="cnic"
                        placeholder="61101XXXXXXXX"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
            
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required|integer|length:11"
                  >
                    <b-form-group
                      label-for="phone"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template #label>
                        Phone <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="phone"
                        v-model="phone"
                        :state="errors.length > 0 ? false : null"
                        name="phone"
                        placeholder="Phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
            
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    :rules="{ required, regex: /^[A-Za-z\s]{1,50}$/ }"
                  >
                    <b-form-group
                      label-for="name"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template #label>
                        Name <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="name"
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

            </template>
            <template v-if="selectedBookingFor && selectedBookingFor.name==='Serving'">
             <b-row>  
                <b-col md="12" class="px-2 py-2 d-flex justify-content-center">
                  <validation-provider
                    #default="{errors}"
                    name="Duty Status"
                    :rules="{ required }"
                    >
                        <b-form-radio-group
                          id="onDutyStatus"
                          v-model="onDutyStatus"
                          name="onDutyStatus"
                        >
                          <b-form-radio value="onDuty" class="md-6 mr-5">On Duty</b-form-radio>
                          <b-form-radio value="onLeave" class="md-6">On Leave</b-form-radio>
                        </b-form-radio-group>  
                        <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row> 
            </template>
            <template v-if="selectedMess">
            <b-row>
              <b-col>
                  <validation-provider #default="{ errors }" name="RoomType" :rules="{ required }">
                      <b-form-group label-for="roomtype" :state="errors.length > 0 ? false : null">
                          <template #label>
                            Room Type <span class="text-danger">*</span>
                          </template>
                          <b-row>
                              <b-col>   
                                  <v-select id="roomtype" inputId="id" label="name" v-model="selectedRoomType"
                                  :options="roomcategories" placeholder="Room Type" class="v-style-chooser"/>
                              </b-col>
                          </b-row>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                          </b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>                
              </b-col>
            </b-row>
            </template>
            <template v-if="selectedMess && checkinDate && checkoutDate && selectedRoomType">
              <b-row>
                  <b-col md="12" sm="12" lg="12" xl="12">
                    <validation-provider
                      #default="{ errors }"
                      name="rooms"
                      :rules="{
                        required: true,
                      }"
                    >
                      <!-- <b-form-group
                        label-for="rooms"
                        :state="errors.length > 0 || ( selectedRooms.length > 2 ? true : false) ? false : null"
                      > -->
                      <b-form-group
                        label-for="rooms"
                        :state="errors.length > 0 ? false : null"
                      >
                        <label>
                          Rooms <span class="text-danger">*</span>
                        </label>
                        <b-row>
                          <b-col>
                            <!-- <b-overlay :show="showSpinner" spinner-variant="primary" no-wrap> -->
                              <v-select
                                inputId="id"
                                label="displayName"
                                v-model="selectedRooms"
                                :options="filteredRooms"
                                multiple
                                autoscroll
                              ></v-select>
                              <b-overlay :show="showSpinner" spinner-variant="primary" no-wrap>
                                <span>Loading...</span>
                              </b-overlay>
                            <!-- </b-overlay> -->
                          </b-col>
                        </b-row>
                        <!-- <b-form-invalid-feedback
                          :state="errors.length > 0 || ( selectedRooms.length > 2 ? true : false) ? false : null"
                        > -->
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          <!-- {{ errors.length > 0 ? errors[0] : selectedRooms.length > 2 ? 'Only 2 rooms or less can be booked in a single booking' : null }} -->
                           {{ errors.length > 0 ? errors[0] : null}}
                        </b-form-invalid-feedback>
                        <!-- <small class="text-danger">
                          {{ errors.length > 0 ? errors[0] : selectedRooms.length > 2 ? 'Only 2 rooms or less can be booked in a single booking' : null }}
                        </small> -->
                      </b-form-group>
                    </validation-provider>
                  </b-col>
              </b-row>
            </template>
            <b-row>
                <b-col>
                    <b-form-group label="TotalPayment" label-for="totalPayment">
                    <template #label>
                        Total Payment <span class="text-danger">*</span>
                    </template>
                    <validation-provider #default="{ errors }" name="TotalPayment"
                        :rules="{ required, regex: /^([0-9]){1,6}$/i }">
                        <b-form-input id="totalPayment" v-model="totalPayment"
                        :state="errors.length > 0 ? false : null"
                        placeholder="TotalPayment" disabled readonly/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="AdditionalRequests" label-for="additionalRequests">
                  <template #label>
                    Additional Requests <span class="text-danger">*</span>
                  </template>
                    <validation-provider #default="{ errors }" name="AdditionalRequests"
                        :rules="{ regex: /^[A-Z\s,.]*$/i }">
                        <b-form-input id="additionalRequests" v-model="additionalRequests" :state="errors.length > 0 ? false : null"
                        placeholder="Additional Requests" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="getLoggedInUser.role_data.code_name == 'su' || getLoggedInUser.role_data.code_name == 'bk__agm'">
              <b-col>
                  <b-form-checkbox
                    id="isGratis"
                    v-model="isGratis"
                    name="isGratis"
                  >
                    Gratis
                  </b-form-checkbox>
              </b-col>
            </b-row>
            <b-form-group class="text-right">
              <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm" :disabled="allDisabled">
                Create
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </template>

    <b-overlay :show="show" spinner-variant="primary" no-wrap />
    <b-overlay :show="showAlertOverlay" no-wrap>
          <template #overlay>
            <div class="text-center">
              <div>
                <div class="my-3 p-2">
                  <feather-icon icon="AlertTriangleIcon" size="60" class="mr-1 mt-1"/>
                  <h1 class="alert-overlay-text-size" style="color: red !important">ALERT</h1>
                  <hr>
                  <h1 style="color: red !important">Please read Cancellation and Early Checkout Policies carefully before proceeding further, since it has financial implications.</h1>
                </div>
                <b-button
                  ref="alertOverlayOkayButton"
                  variant="primary"
                  size="lg"
                  @click="showAlertOverlay = false"
                  class="mb-5"
                >
                  Okay
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  import moment from "moment";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal"],
    data() {
      return {
        showSpinner: false,
        show:false,
        selectedBookingFor: null,
        bookingforOptions: [
          { id: 2, name: "Serving" },
          { id: 3, name: "Retired" },
          { id: 4, name: "Civilian" },
      ],
      roomcategories: [
          { id: 1, name: "Standard" },
          { id: 2, name: "One Room Suite" },
          { id: 3, name: "Two Room Suite" },
      ],
        selectedRoomType: null,
        messes: [],
        selectedMess: null,
        //-------------------- city changes 
        cities: [],
        selectedCity: null,
        //-------------------- city changes 
        rooms: [],
        filteredRooms: [],
        selectedRooms: [],
        additionalRequests: "",
        // totalPayment: 0,
        checkinDate: "",
        checkinDateObj: null,
        checkoutDate: "",
        checkoutDateObj: null,
        required,
        isBusy: true,
        messDisabled: false,
        isGratis: false,
        name:"",
        phone: "",
        cnic: "",
        onDutyStatus: false,
        //--------------------------------- city changes 
        messSecretary: false,
        messReceptionist: false,
        //--------------------------------- city changes 
        showAlertOverlay: false,
        maxCheckinDate: "",
        maxCheckinDateObj: null,
        minCheckoutDate: "",
        minCheckoutDateObj: null,
        maxCheckoutDate: "",
        maxCheckoutDateObj: null,
        minCheckinDate: "",
        minCheckinDateObj: null,
        allDisabled: false,
      };
    },
    async mounted() {
      if (this.showModal) {
        this.showAlertOverlay = true;
        try {
            await this.fetchGlobalPoliciesState()

            if(!this.getGlobalPoliciesState)
            {
              this.setCheckinDate(await this.getCurrentDate())
              this.setCheckoutDate(await this.getCurrentDate())
              this.allDisabled = true
              return 
            }

            // this.minCheckinDateObj = await this.getCurrentDate()
            // this.minCheckinDate = await this.getPakistanDateString(this.minCheckinDateObj);
            
            if( !(this.getLoggedInUser.role_data.code_name == 'su') && !(this.getLoggedInUser.role_data.code_name == 'bk__agm')){
              // this.maxCheckinDate = this.calculateMaxCheckinDate()
              }
              this.isBusy = true
              
            // await this.updateMaxCheckinDate();
            // await this.setCheckinDate(this.minCheckinDateObj);
            // city changes 
            // ------------------------------------------------------------
            let res= await this.getBookingCitiesUnpaginated({
              mess_only: true,
            });
            this.cities = res.data
            // ------------------------------------------------------------
            // city changes 

            //--------------------------------- city changes 
            // let res= await this.getMessesUnpaginated({})
            // this.messes = res.data
            //--------------------------------- city changes 
            // res = await this.getRoomsUnpaginated()
            // this.rooms = res.data
            // this.filteredRooms = this.rooms


            //--------------------------------- city changes 
            // if(this.getLoggedInUser.mess_secretary){
            //   for(let i=0; i<this.messes.length; i++){
            //     if(this.messes[i].id == this.getLoggedInUser.mess_secretary.mess){
            //       this.selectedMess = this.messes[i]
            //       break;
            //     }
            //   }
            //   this.messDisabled=true
            // }
            // else if(this.getLoggedInUser.clerk){
            //   for(let i=0; i<this.messes.length; i++){
            //     if(this.messes[i].id == this.getLoggedInUser.clerk.mess){
            //       this.selectedMess = this.messes[i]
            //       break;
            //     }
            //   }
            //   this.messDisabled=true
            // }      
            //
            //------------------------------------------ city changes


            //city changes
            // -----------------------------------------------------------------------
            if(this.getLoggedInUser.mess_secretary){
              let res= await this.getMess(this.getLoggedInUser.mess_secretary.mess)
              this.messes.push(res.data)
              this.selectedMess = res.data
              this.messSecretary = true
              this.messDisabled=true
            }         
            else if(this.getLoggedInUser.clerk){
              let res= await this.getMess(this.getLoggedInUser.clerk.mess)
              this.messes.push(res.data)
              this.selectedMess = res.data
              this.messReceptionist = true
              this.messDisabled=true
            } 
            // --------------------------------------------------------------------
            //city changes

            this.isBusy = false
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        createBooking: "appData/createBooking",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getRooms: "appData/getRooms",
        getRoomsUnpaginated: "appData/getRoomsUnpaginated",
        getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
        getMess: "appData/getMess",
        fetchGlobalPoliciesState: "appData/fetchGlobalPoliciesState",
        fetchSystemTime: "appData/fetchSystemTime",
      }),
      async setCheckinDate(tempDate) {
        var checkinDateObj = new Date(tempDate.getTime())
        var checkinDate = await this.getPakistanDateString(tempDate);
        this.checkinDateObj = checkinDateObj
        this.checkinDate = checkinDate;
      },
      async setCheckoutDate(tempDate) {
        var checkoutDateObj = new Date(tempDate.getTime())
        var checkoutDate = await this.getPakistanDateString(tempDate);
        this.checkoutDateObj = checkoutDateObj
        this.checkoutDate = checkoutDate;
      },
      async updateMaxCheckinDate() {
        var maxCheckinDateObj = new Date(this.minCheckinDateObj.getTime())
        var maxCheckinDate = await this.getPakistanDateString(maxCheckinDateObj);
        // let bookingValidity = parseInt(this.getGlobalPoliciesState.max_days_adv_booking);
        let bookingValidity = parseInt(this.getGlobalPoliciesState.max_days_adv_booking_admin);
        if(!bookingValidity || bookingValidity == 0)
        {
          this.allDisabled = true
          this.maxCheckinDate = maxCheckinDate;
          return
        }
        maxCheckinDateObj.setDate(this.minCheckinDateObj.getDate() + bookingValidity);
        maxCheckinDate = await this.getPakistanDateString(maxCheckinDateObj);
        this.maxCheckinDateObj = maxCheckinDateObj
        this.maxCheckinDate = maxCheckinDate;
      },
      async updateCheckoutDate() {
        const nextDay = new Date(this.checkinDateObj.getTime())
        nextDay.setDate(nextDay.getDate() + 1);

        var minCheckoutDateObj = new Date(nextDay.getTime());
        var minCheckoutDate = await this.getPakistanDateString(minCheckoutDateObj);

        var maxCheckoutDateObj = new Date(nextDay.getTime());
        var maxCheckoutDate = await this.getPakistanDateString(maxCheckoutDateObj);

        // let maxBookingDays = parseInt(this.getGlobalPoliciesState.max_booking_days);
        let maxBookingDays = parseInt(this.getGlobalPoliciesState.max_booking_days_admin);
        if(!maxBookingDays || maxBookingDays == 0)
        {
          this.allDisabled = true
          this.minCheckoutDate = minCheckoutDate
          this.maxCheckoutDate = maxCheckoutDate
          return
        }

        // maxCheckoutDateObj.setDate(this.checkinDateObj.getDate() + maxBookingDays);
        maxCheckoutDateObj.setDate(nextDay.getDate() + (maxBookingDays - 1)); 
        maxCheckoutDate = await this.getPakistanDateString(maxCheckoutDateObj);

        this.minCheckoutDateObj = minCheckoutDateObj
        this.minCheckoutDate = minCheckoutDate;
        if( !(this.getLoggedInUser.role_data.code_name == 'su') && !(this.getLoggedInUser.role_data.code_name == 'bk__agm')){
          this.maxCheckoutDateObj = maxCheckoutDateObj
          this.maxCheckoutDate = maxCheckoutDate;
        }
      },
      async getCurrentDate() {
        await this.fetchSystemTime()
        var systemDateTime  = await this.getSystemTime;
        var currentDate = new Date(systemDateTime);
        return currentDate
      },
      async getPakistanDateString(date){
        date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
        var day = date.split("/")[1]
        var month = date.split("/")[0]
        var year = date.split("/")[2]
        return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
      },

      //city changes
      // -------------------------------------------------------------------------------
      async updateMess()
      {
        try{
          this.selectedMess = null
          let res = await this.getMessesUnpaginated({city: this.selectedCity.id})
          this.messes = res.data
        }
        catch(error)
        {
          this.displayError
        }
      },
      // -----------------------------------------------------------------------------------
      //city changes

      async validationForm() {
        const success = await this.$refs.bookingCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },

      async submit() {
        try {
          this.show = true;
          let formData = new FormData();
  
          let selectedRoomIds = []
          for(let i=0; i<this.selectedRooms.length; i++){
            selectedRoomIds.push(this.selectedRooms[i].id)
          }
          let payment = this.isGratis ? 0 : this.totalPayment; 
          let Bookingstatus = this.isGratis ? 2 : 1; 
          let paymentStatus = this.isGratis ? 2 : 1;
          let onDutyStatusValue = this.onDutyStatus === 'onDuty';
          let dataToInsert = {
            customer_id: this.getLoggedInUser.id,
            mess: this.selectedMess.id,
            rooms_id: selectedRoomIds,
            additional_requests: this.additionalRequests ? this.additionalRequests : null,
            start_date_time: moment(this.checkinDate).set({ hour: 16, minute: 0, second: 0 }).format(
              "YYYY-MM-DD HH:mm:SS"
            ),
            end_date_time: moment(this.checkoutDate).set({ hour: 12, minute: 0, second: 0 }).format(
              "YYYY-MM-DD HH:mm:SS"
            ),

            total_payment: payment,
            gratis: this.isGratis,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
            booking_flow: 1,
            booking_for_category: this.selectedBookingFor.id - 1,
            status: Bookingstatus,
            payment_status: paymentStatus,
            booking_for: 2,
            on_duty: onDutyStatusValue,
          };
          dataToInsert ["guest_data"] = {
                    name: this.name,
                    cnic: this.cnic,
                    mobile: this.phone,
                  }  
          formData.append("data", JSON.stringify(dataToInsert));
  
          const res = await this.createBooking(formData);
          if (res.status === 201) {
            this.show = false;
            this.$swal({
              title: "Booking created successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("booking-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      async refreshAvailableRooms() {
        try {
          this.showSpinner = true
          let tempParams = {
            available_rooms: this.checkinDate + ',' + this.checkoutDate,
            mess: this.selectedMess.id
          };
          if (this.getLoggedInUser.mess_secretary) {
            tempParams["no_agm_rooms"] = true;
          } else if (this.getLoggedInUser.clerk) {
            tempParams["no_agm_rooms"] = true;
          } else if (this.getLoggedInUser.role_data.code_name == 'bk__agm_dd') {
            tempParams["only_agm_rooms"] = true;
          }
          let res = await this.getRoomsUnpaginated(tempParams);
          this.rooms = res.data;

          if (this.selectedMess && this.selectedRoomType) {
            this.filteredRooms = this.rooms.filter((room) =>
              room.mess == this.selectedMess.id && room.room_type_data.name == this.selectedRoomType.name
            ).map(room => ({
              ...room,
              displayName: `${room.name}  ${ !(room.starting_category_name.toLowerCase().includes('none')) ? ` (${room.starting_category_name})` : ''}  ${ !(room.starting_lien_name.toLowerCase().includes('none')) ? ` (${room.starting_lien_name})` : ''}`
            }
            ));
          } else {
            this.filteredRooms = this.rooms.map(room => ({
              ...room,
              displayName: `${room.name}  ${ !(room.starting_category_name.toLowerCase().includes('none')) ? ` (${room.starting_category_name})` : ''}  ${ !(room.starting_lien_name.toLowerCase().includes('none')) ? ` (${room.starting_lien_name})` : ''}`
            }));
          }

          this.showSpinner = false
        } catch (error) {
          console.error('Error refreshing available rooms:', error);
          this.showSpinner = false
        }
      },
     
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ 
          getLoggedInUser: "appData/getUser",
          getGlobalPoliciesState: "appData/getGlobalPoliciesState",
          getSystemTime: "appData/getSystemTime",          
        }),

      nightsStayed() {
        if (!this.checkinDate || !this.checkoutDate) return 0;
        const checkIn = moment(this.checkinDate, 'YYYY-MM-DD');
        const checkOut = moment(this.checkoutDate, 'YYYY-MM-DD');
        const nights = checkOut.diff(checkIn, 'days');
        return Math.max(nights, 0); 
      },
      totalPayment() {

        if (this.isGratis) {
          return 0;
        }
        if (!this.selectedRooms.length || !this.checkinDate || !this.checkoutDate || !this.selectedMess || !this.selectedBookingFor) {
          return 0;
        }

        const nightsStayed = this.nightsStayed;
        let total = 0;

        this.selectedRooms.forEach(room => {
          //------------------------------- city changes
          const roomTypeDetail = this.selectedMess.room_type_details.find(detail => {
            const roomTypeId = typeof detail.room_type === 'object' ? detail.room_type.id : detail.room_type;
            return roomTypeId === room.room_type && detail.room_category === this.selectedBookingFor.id;
          //------------------------------- city changes
          });
          if (roomTypeDetail) {
            total += parseInt(roomTypeDetail.price, 10);
          }
        });

        return total * nightsStayed;
      },
    },
    watch: {


      //city changes
      //----------------------------------------------------------------
    async selectedCity(newValue, oldValue)
      {
        try {
          if (newValue) {
            this.messes = []
            await this.updateMess();
          } else {
            return
          }
        } catch (error) {
          console.error('Error in selectedMess watch:', error);
        }
      },
    //-------------------------------------------------------------------
    //city changes
      async selectedRoomType(newValue, oldValue) {
          try {
            // this.show = true;
            if (newValue) {
              this.selectedRooms = [];
              if(this.selectedMess){
                await this.refreshAvailableRooms();
              }
              // this.show = false;
            }
          } catch (error) {
            console.error('Error in selectedRoomType watch:', error);
          }
        },

      async checkinDate(newValue, oldValue) {
          try {
            // this.checkinDateObj = new Date(newValue + ' 00:00:00 +05:00');

            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;
            this.checkinDateObj = new Date(formattedDateString);

            await this.updateCheckoutDate();
            this.checkoutDate = this.minCheckoutDate;
            if (newValue && newValue > this.checkoutDate) {
              this.checkoutDate = newValue;
            }
            if (this.checkoutDate && this.selectedMess) {
              await this.refreshAvailableRooms();
            } else {
              this.rooms = [];
              this.filteredRooms = [];
            }
            this.selectedRooms = [];
          } catch (error) {
            console.error('Error in checkinDate watch:', error);
          }
        },
      async checkoutDate(newValue, oldValue) {
        try {
          // this.checkoutDateObj = new Date(newValue + ' 00:00:00 +05:00');

          let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
          let parts = dateStringISO8601.split(' ');
          let datePart = parts[0]; // '2024-07-05'
          let timePart = parts[1]; // '00:00:00'
          let offsetPart = parts[2]; // '+05:00'
          let formattedDateString = datePart + 'T' + timePart + offsetPart;
          this.checkoutDateObj = new Date(formattedDateString);

          if (newValue && newValue < this.checkinDate) {
            this.checkinDate = newValue;
          }
          if (this.checkinDate && this.selectedMess) {
            await this.refreshAvailableRooms();
          } else {
            this.rooms = [];
            this.filteredRooms = [];
          }
          this.selectedRooms = [];
        } catch (error) {
          console.error('Error in checkoutDate watch:', error);
        }
      },
      async selectedMess(newValue, oldValue) {
        try {
          if (newValue) {

            // let cityMinimumDateObj = new Date(newValue.city.minimum_booking_date + ' +05:00');

            let dateStringISO8601 = newValue.city.minimum_booking_date + ' +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;
            let cityMinimumDateObj = new Date(formattedDateString);

            let cityMinimumDate = await this.getPakistanDateString(cityMinimumDateObj);

            this.minCheckinDateObj = await this.getCurrentDate()
            this.minCheckinDate = await this.getPakistanDateString(this.minCheckinDateObj);            

            if(cityMinimumDateObj > this.minCheckinDateObj){
              this.minCheckinDateObj = new Date(cityMinimumDateObj.getTime())
              this.minCheckinDate = cityMinimumDate
            }

            // if(newValue.city.name.toLowerCase().includes('murree addl'))
            // {
            //   this.minCheckinDate = "2024-07-08"
            //   this.minCheckinDateObj = new Date(this.minCheckinDate + ' 00:00:00 +05:00');
            // }
            // else{
            //   this.minCheckinDateObj = await this.getCurrentDate()
            //   this.minCheckinDate = await this.getPakistanDateString(this.minCheckinDateObj);
            // }
            
            if( !(this.getLoggedInUser.role_data.code_name == 'su') && !(this.getLoggedInUser.role_data.code_name == 'bk__agm')){
              await this.updateMaxCheckinDate();
            }
            await this.setCheckinDate(this.minCheckinDateObj);              
            await this.refreshAvailableRooms();
            this.selectedRooms = this.selectedRooms.filter(room => room.mess === newValue.id);
            this.selectedRooms = [];
            // if (this.rooms) {
            //   this.filteredRooms = this.rooms.filter((room) => room.mess == newValue.id);
            // } else {
            //   this.rooms = [];
            //   this.filteredRooms = [];
            // }
          } else {
            this.filteredRooms = this.rooms;
            this.selectedRooms = [];
          }
        } catch (error) {
          console.error('Error in selectedMess watch:', error);
        }
      },
      // selectedRooms(newValue, oldValue) {
      //   if (newValue) {
      //       // if(newValue.length < 3)
      //       // {
      //         let total = 0
      //         for(let i=0; i<newValue.length; i++)
      //         {
      //             let filteredRoomTypeDetail = null
      //             filteredRoomTypeDetail = this.selectedMess.room_type_details.filter((room_type_detail) => (room_type_detail.room_type == newValue[i].room_type) && (room_type_detail.room_category == this.selectedBookingFor.id) );
      //             total += parseInt(filteredRoomTypeDetail[0].price)
      //         }
      //         // this.totalPayment = total
      //         this.totalPayment = total * this.nightsStayed;
      //       // }
      //       // else{
      //       //   this.$refs.bookingCreateFormValidation.setErrors({
      //       //     rooms: ["Only 2 rooms or less can be booked in a single booking"],
      //       //   });
      //       //   // this.$refs.bookingCreateFormValidation.errors.rooms.push("Only 2 rooms or less can be booked in a single booking")
      //       // }
      //   }
      // },
      selectedBookingFor(newValue, oldValue, selectedRooms) {
        if (newValue) {
          if (this.selectedRooms) {
              let total = 0
              for(let i=0; i<this.selectedRooms.length; i++)
              {
                  let filteredRoomTypeDetail = null
                  filteredRoomTypeDetail = this.selectedMess.room_type_details.filter((room_type_detail) => (room_type_detail.room_type == this.selectedRooms[i].room_type) && (room_type_detail.room_category == this.selectedBookingFor.id) );
                  total += parseInt(filteredRoomTypeDetail[0].price)
              }
              this.totalPayment = total
                      
          }
          
        }
      }
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  .alert-overlay-text-size {
    font-size: 30px;
  }
  </style>
  